import React, { useState } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTripadvisor } from "react-icons/fa";
import constant from "../../Template/Template7Pages/constant";
import { NavLink } from "react-router-dom";
import media from "../../global/media";
import { propertyImageURL } from "../../global/constants";
import data from "../../../hotelData.json";
import { Button, Modal, Form, Container, InputGroup, FormControl, DropdownButton, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import JoinOurNewsLetter from "./JoinOurNewsLetterWidget";
import { IconName } from "react-icons/fa";
// import Lightbox from "react-image-lightbox";

const FooterLayout7Wrapper = styled.div`
width: 100%;
color: #fff;
padding: 2em 0;
line-height: 1.8;
background: #${(data.colorConfiguration.headercolor ? data.colorConfiguration.headercolor : "000000")};
.contact {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase;
  letter-spacing: 1.4;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  li {
    padding: 0 10px;
    .contact-label {
      /* color: ${constant.primaryColor}; */
      color: #919191;
      padding-right: 10px;
    }
    &.contact-email {
      text-transform: none;
    }
  }
}
h3 {
  margin-bottom: 20px;
  color: #fff;
}
a {
  color: #fff;
  text-decoration: none;
  &:hover {
    color: ${constant.secondaryColor};
  }
}
img {
  height: 50px;
  ${media.md} {
    height: 150px;
  }
}
.gallery {
  .image-wrap:hover {
    transition: all 0.4s ease-in-out;
    transform: scale(1.1);
    opacity: 0.7;
  }
  img {
    width: 100%;
    object-fit: cover;
    height: 50px;
    cursor: pointer;
  }
}
`;
const SocialWrapper = styled.div`
display: flex;
justify-content: center;
margin: 20px 0;
flex-wrap: wrap;
h3 {
  text-align: center;
  color: #fff;
  width: 100%;
  margin-bottom: 5px;
}
`;

const SocialLink = styled.a`
color: #fff;
border-radius: 5px;
margin-right: 20px;
font-size: 30px;
border: 0;
svg {
  color: #fff;
}
&:hover {
  color: ${constant.secondaryColor};
  border: 0;
  svg {
    color: ${constant.secondaryColor};
  }
}
`;

const NavBar = styled.div`
display: flex;
justify-content: center;
flex-wrap: wrap;
margin-bottom: 20px;
.nav-link {
  color: #fff;
  padding: 0 10px;
  font-size: 11px;
  text-transform: uppercase;
}
`;


const FooterLayout7 = ({
  contactInfo,
  socialLinks,
  propertyDetails,
  propertyImages,
  menuLinks,
  footerTop,
  // onNavClick,
  reviewMinderWidget,
  data
}) => {

  //FOR API CALLING
  const [fname, setfname] = useState('');
  const [lname, setlname] = useState('');
  const [email, setemail] = useState('');
  const [country, setcountry] = useState('');

  const joinnewsletter = (e) => {
    e.preventDefault();
   // console.log(fname + lname + email + country);
    axios.post(`https://newsletter.staah.net/newsletter_registration.php`, { name: fname + lname, email: email, country: country, prop_id: data.prop_id, prop_type: data.prop_type })
      .then(res => {
        //console.log(res);
        //console.log(res.data);
        //console.log("success");
      })
      .catch(error => {
       // console.log("ERROR ==== ", error);
      })
  }

  // const [open, setOpen] = useState(false);
  // // const [photoIndex, setPhotoIndex] = useState(0);
  const images = [];
  propertyImages.image.map((value) => images.push(propertyImageURL + value));

  const onNavExternalClick = (externalLink) => {
    window.open(externalLink, "_blank");
  };
  // const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const style = {
    "height": '340px',
  }

  return (
    <FooterLayout7Wrapper>
      <Grid>
        <Row>
          <Col xs={12}>
            <ul className="contact">
              <li>
                <p>
                  {decodeURIComponent(contactInfo.address.location)}{" "}
                  {contactInfo.address.city}, {contactInfo.address.country}
                </p>
              </li>
              {contactInfo.telephone.phone && (
                <li>
                  <p>
                    <span className="contact-label">T</span>
                    <a
                      href={
                        "tel:" +
                        decodeURIComponent(contactInfo.telephone.phone).replace(
                          /\s/g,
                          ""
                        )
                      }
                    >
                      {decodeURIComponent(contactInfo.telephone.phone)}
                    </a>
                  </p>
                </li>
              )}
              {contactInfo.telephone.fax && (
                <li>
                  <p>
                    <span className="contact-label">F</span>
                    {decodeURIComponent(contactInfo.telephone.fax)}
                  </p>
                </li>
              )}
              {contactInfo.freephone && (
                <li>
                  <p>
                    <span className="contact-label">T</span>
                    {decodeURIComponent(contactInfo.freephone)}
                  </p>
                </li>
              )}
              {contactInfo.email && (
                <li>
                  <p className="contact-email">
                    <span className="contact-label">E</span>
                    <a href={"mailto:" + decodeURIComponent(contactInfo.email)}>
                      {decodeURIComponent(contactInfo.email)}
                    </a>
                  </p>
                </li>
              )}
              {contactInfo.website && (
                <li>
                  <p className="contact-email">
                    <span className="contact-label">W</span>
                    <a href={"http://" + decodeURIComponent(contactInfo.website)} target="_blank">
                      {decodeURIComponent(contactInfo.website)}
                    </a>

                  </p>
                </li>
              )}
            </ul>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <SocialWrapper>

              {
                data.is_newsletter === "1"
                  ?
                  <>
                    <JoinOurNewsLetter data={data} />
                  </>
                  :
                  <>
                  </>

              }



              <h3>JOIN THE CONVERSATION</h3>
              {socialLinks.facebook && (
                <SocialLink target="_blank" href={socialLinks.facebook}>
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                </SocialLink>
              )}
              {socialLinks.twitter && (
                <SocialLink target="_blank" href={socialLinks.twitter}>
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </SocialLink>
              )}
              {socialLinks.linkedIn && (
                <SocialLink target="_blank" href={socialLinks.linkedIn}>
                  <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
                </SocialLink>
              )}
              {socialLinks.googelepluse && (
                <SocialLink target="_blank" href={socialLinks.googelepluse}>
                  <FontAwesomeIcon icon={["fab", "google-plus-g"]} />
                </SocialLink>
              )}
              {socialLinks.tripadvisor && (
                <SocialLink target="_blank" href={socialLinks.tripadvisor}>
                  <i class="fa fa-tripadvisor"></i>
                </SocialLink>
              )}
              {socialLinks.instagram && (
                <SocialLink target="_blank" href={socialLinks.instagram}>
                  <FontAwesomeIcon icon={["fab", "instagram"]} />
                </SocialLink>
              )}
            </SocialWrapper>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <NavBar>
              {menuLinks.map((item, index) =>
                item.menuSlugName === "/reviews/" ||
                  item.menuSlugName === "/review/" ? (
                  reviewMinderWidget === "1" && (
                    <NavLink
                      key={index}
                      activeClassName="active-link"
                      onClick={footerTop}
                      className="nav-link"
                      to={item.menuSlugName}
                      exact
                    >
                      {item.menuLinkName}
                    </NavLink>
                  )
                ) : item.menuExternalLink ? (
                  <NavLink
                    key={index}
                    activeClassName="active-link"
                    className="nav-link"
                    onClick={() => onNavExternalClick(item.menuExternalLink)}
                    to="#"
                  >
                    {item.menuLinkName}
                  </NavLink>
                ) : (
                  <NavLink
                    key={index}
                    activeClassName="active-link"
                    onClick={footerTop}
                    className="nav-link"
                    to={item.menuSlugName}
                    exact
                  >
                    {item.menuLinkName}
                  </NavLink>
                )
              )}
            </NavBar>
          </Col>
        </Row>
      </Grid>
    </FooterLayout7Wrapper>
  );
};

export default FooterLayout7;
