import React from "react";
import styled from "styled-components";
import {
  sliderImageURL,
  noImageUrl,
  decodeContent,
  bookRoomUrl,
} from "../../global/constants";
import constant from "./constant";
import media from "../../global/media";
import { NavLink } from "react-router-dom";

const AccommodationImageWrap = styled.div`
  background: #fff;
  text-decoration: none;
  color: ${constant.bodyColor};
  display: block;
  width: 100%;

  ${media.md} {
    width: 50%;
    height : 400px !important;
  }
  .card-img {
    overflow: hidden;
    position: relative;
    height: 100%;
    background-size: cover;
    .card-img-top {
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      transition: 1s;
      pointer-events: none;
      width: 100%;
      height: 100%;
      &:nth-child(1) {
        opacity: 1;
        filter: blur(0);
        transform: translateX(0%) scaleX(1);
        position: relative;
      }
      &:nth-child(2) {
      }
    }
    &:hover {
      .card-img-top:nth-child(2) {
        opacity: 0;
        filter: blur(10px);
        transform: translateX(-50%) scaleX(2);
      }
      .card-img-top:nth-child(1) {
        opacity: 1;
        filter: blur(0);
        transform: translateX(0%) scaleX(1);
      }
    }
  }
  position: relative;

  h3 {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    text-shadow: 1px 1px 6px #000;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-style: normal;
    text-decoration: none;
    word-break: normal;
    ${media.sm} {
      font-size: 1.5rem;
    }
  }
  .accommodationBtn {
    display: flex;
    flex-direction: column;
    justify-content: left;

    align-items: flex-start;
    position: absolute;
    bottom: 10%;
    left: 10%;
  }
`;

const LinkWrapper = styled(NavLink)`
  color: #fff !important;
  position: relative;
  text-align: center;
  text-decoration: none !important;

  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px 30px;
  border-radius: 5px;
  background: ${constant.buttonColor};
  &:hover {
    background: ${constant.blackColor};
  }
  &.heading {
    background: none;
    position: absolute;
    padding: 0;
    border-radius: 0;
    text-decoration: none;
    top: 10%;
    left: 10%;
    width: 70%;
  }
  &:first-child {
    background: none;
    text-decoration: none;
    color: #fff;
    border: 0;
    padding: 0;
    font-style: italic;
    &:hover {
      color: ${constant.primaryColor};
    }
  }
`;

const RoomDetailHomePageComponent = ({ value, mainSlugName }) => (
  <AccommodationImageWrap>
    <LinkWrapper
      onClick={() => window.scrollTo(0, 0)}
      to={mainSlugName + value.RoomTypeName.slugName}
    >
      {value?.RoomTypeImages?.Images?.length > 0 ? (
        <div className="card-img">
          <img
            src={sliderImageURL + value.RoomTypeImages.Images[1]}
            alt=""
            className="card-img-top"
          />
          <img
            src={sliderImageURL + value.RoomTypeImages.Images[0]}
            alt=""
            className="card-img-top"
          />
        </div>
      ) : (
        <img src={noImageUrl} class="card-img-top" alt="" />
      )}
    </LinkWrapper>

    <LinkWrapper
      onClick={() => window.scrollTo(0, 0)}
      to={mainSlugName + value.RoomTypeName.slugName}
      className="heading"
    >
      <h3>{value.RoomTypeName.section}</h3>
    </LinkWrapper>
    <div className="accommodationBtn">
      <LinkWrapper
        onClick={() => window.scrollTo(0, 0)}
        to={mainSlugName + value.RoomTypeName.slugName}
      >
        read more >>
      </LinkWrapper>
      <LinkWrapper to={`${bookRoomUrl(value.RoomcatgId)}`} target="_blank">
        Book Now
      </LinkWrapper>
    </div>
  </AccommodationImageWrap>
);
export default RoomDetailHomePageComponent;
