import React, { useState } from "react"
import styled from "styled-components"
import { Row, Col } from "react-flexbox-grid"
import Lightbox from "react-image-lightbox"
import CommonComponent from "./CommonComponent"
import { propertyImageURL, sliderImageURL } from "../../global/constants"
import "react-image-lightbox/style.css"
import constant from "./constant"
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";


const GalleryWrapper = styled.div`
    margin-top: 40px;
`
const GalleryImageWrap = styled.div`
    height: 250px;
    margin-bottom: 20px;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        cursor: pointer;
        &:hover {
            padding: calc(2% - 3px);
        }
    }
`

const ClickItemWrapper = styled.div`
    text-align: center;
`

const ClickItem = styled.div`
    cursor: pointer;
    display: inline-block;
    padding: 0 10px;
    &:hover {
        color: ${constant.secondaryColor};
    }
    &.active {
        color: ${constant.secondaryColor};
        border-bottom: 1px solid ${constant.secondaryColor};
    }
`
const GalleryComponent = ({ propertyImage, roomTypes }) => {
    const [open, setOpen] = useState(false)
    const [photoIndex, setPhotoIndex] = useState(0)
    const [display, setDisplay] = useState(0)
    const images = []
    propertyImage.image.map((value, index) =>
        images.push({
            image: propertyImageURL + value,
            imgLabel: atob(propertyImage.imgLabel[index]),
            type: "property",
        })
    )
    if (roomTypes.length > 0) {
        roomTypes.map(
            item =>
                item.RoomTypeImages &&
                item.RoomTypeImages.Images.map((item1, index) =>
                    images.push({
                        image: sliderImageURL + item1,
                        imgLabel: atob( item.RoomTypeImages.imgLabel[index]),
                        type: "roomtype",
                    })
                )
        )
    }

    // {console.log(images)}
    const setPhotoDisplay = index => {
        setDisplay(index)
    }
    const openLightbox = (type, key) => {
        setPhotoIndex(key)
        setOpen(true)
    }

    return (
        <div>
            <BreadCrumbComponent currentUrl={"Gallery"} />
            <CommonComponent>
                <h2>
                    Gallery<span></span>
                </h2>
                <ClickItemWrapper>
                    <ClickItem
                        className={display === 0 ? "active" : ""}
                        onClick={() => setPhotoDisplay(0)}
                    >
                        All Photos
                    </ClickItem>
                    <ClickItem
                        className={display === 1 ? "active" : ""}
                        onClick={() => setPhotoDisplay(1)}
                    >
                        Property Photos
                    </ClickItem>
                    <ClickItem
                        className={display === 2 ? "active" : ""}
                        onClick={() => setPhotoDisplay(2)}
                    >
                        Room Photos
                    </ClickItem>
                </ClickItemWrapper>
                <GalleryWrapper>
                    <Row>
                        {(display === 0 || display === 1) &&
                            images.map((value, index) => {
                                return (
                                    value.type === "property" && (
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            key={index}
                                        >
                                            <GalleryImageWrap>
                                                <img
                                                    src={value.image}
                                                    onClick={() =>
                                                        openLightbox(
                                                            "property",
                                                            index
                                                        )
                                                    }
                                                    alt={value.imgLabel}
                                                />
                                            </GalleryImageWrap>
                                        </Col>
                                    )
                                )
                            })}
                        {(display === 0 || display === 2) &&
                            images.map((value, index) => {
                                return (
                                    value.imgLabel !== "" &&
                                    value.type === "roomtype" && (
                                        <Col
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={4}
                                            key={index}
                                        >
                                            <GalleryImageWrap>
                                                <img
                                                    src={value.image}
                                                    onClick={() =>
                                                        openLightbox(
                                                            "roomtypes",
                                                            index
                                                        )
                                                    }
                                                    alt={value.imgLabel}
                                                />
                                            </GalleryImageWrap>
                                        </Col>
                                    )
                                )
                            })}
                    </Row>
                </GalleryWrapper>
            </CommonComponent>
            {open && (
                <Lightbox
                    mainSrc={images[photoIndex].image}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                    }
                    onCloseRequest={() => setOpen(false)}
                    imageCaption={images[photoIndex].imgLabel}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex + images.length - 1) % images.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
        </div>
    )
}

export default GalleryComponent
