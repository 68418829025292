import React, { useEffect,useState } from "react";
import styled from "styled-components";
import MetaTags from "../../global/MetaTags";
import media from "../../global/media";
import { Row, Col } from "react-flexbox-grid";
import { sliderImageURL, decodeContent } from "../../global/constants";
import constant from "./constant";
import HomePageSlider11 from "../../HomePageSlider/HomePageSlider11";
import HomePageComponent from "./HomePageComponent";
import CommonComponent from "./CommonComponent";
import AccommodationComponent from "./AccommodationComponent";
import GalleryComponent from "./GalleryComponent";
import ContactUsComponent from "./ContactUsComponent";
import ReviewsComponent from "./ReviewsComponent";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InsidePageHeaderComponent from "./InsidePageHeaderComponent";
import SubPageCardComponent from "./SubPageCardComponent";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeaderImage = styled.div`
  height: 300px;
  padding-top: 30%;
  background-size: cover;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
  animation-delay: 1s;
  animation: bounceBgImage 10s linear infinite;
  position: relative;
  @keyframes bounceBgImage {
    0%,
    100% {
      background-position: 50% 100%;
    }
    50% {
      background-position: 50% 0;
    }
  }

  .headerContentWrapper {
    margin-bottom: 10px;
    margin-top: 100px;
    z-index: 2;
  }
  .headerContent {
    width: 100%;
    height: 100%;
    h1 {
      text-align: center;
      white-space: pre-wrap;
      font-size: 20px;
      ${media.md} {
        font-size: 50px;
      }
      font-weight: 500;
      margin-bottom: 30px;
      letter-spacing: 0.1em;
      text-shadow: 1px 1px 2px #000;
    }
    h3 {
      white-space: pre-wrap;
      font-size: 18px;
      ${media.md} {
        font-size: 35px;
      }
      font-weight: 400;
      text-align: center;
      margin-bottom: 50px;
    }
  }
  .noLeftMargin {
    margin-left: 0;
  }
  h2 {
    text-shadow: 1px 1px 2px #000;
  }
`;

const NoHeaderImage = styled.div`
  height: 100px;
  background: ${(prop) => prop.bgColor || "#fff"};

  /* ${media.md}{
  height: 100px;
  } */
`;
const SubPagesBlock = styled.div`
  a {
    color: ${constant.secondaryColor};
  }
  margin-top: 45px;
  height: 400px;
  margin-bottom: 20px;
  position: relative;
  background-image: url("${(prop) => prop.bgImage}");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;

  .content-desc {
    transition: visibility 0.3s linear, opacity 0.3s linear;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
  }
  .content-title {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }

  &:hover {
    .content-desc {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const CardWrapper = styled.div`
  margin-top: 60px;
`;

const MultiPageWrapper = styled.div`
  margin-top: 15px;
  padding: 10px 30px;
`;

const SubPagesBlockLink = styled.a`
  height: 400px;
  margin: 50px 0px;
  display: block;
  position: relative;
  background-image: url("${(prop) => prop.bgImage}");
  background-size: cover;
  width: 100%;
  .content-title {
    position: absolute;
    color: #000;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }
`;
const ParallaxWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0px);
  transform-style: preserve-3d;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
  background-size: cover;
  overflow-y: hidden;
  /* background-attachment:fixed; */
`;

const SliderWrapper = styled.div`
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58, 49, 56, 0.8);
    &:before {
      font-size: 30px;
    }
    &:hover {
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    left: 20px;
    top: 40%;
  }
  .slick-next {
    right: 20px;
    top: 40%;
  }
`;
const SlideImage = styled.div`
  height: 300px;
  img {
    width: 100%;
  }
`;

const PagesComponent = ({ data, templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const facilities = templateData.propertyDetails.facilities.facility;
  const propertyImage = templateData.propertyImages;
  const noImageUrl = "/images/multipages/noimage.png";
  const MenuTypeDetail = templateData.menuLinks.menuLink;
  const MultiPageDetail = templateData.multiPagesMain.mainPage;
  const cardPropertyImages = templateData.propertyImages.image;
  const [bookingEngine] = useState(templateData.bookingEngine);

  let multiPage = 1;

  // const roomDetailPage=[];
  // templateData.roomTypes.roomType.map((item1,index)=>{
  //     const roomDetailPage= templateData.menuLinks.menuLink.filter((item) =>
  //     item.menuSlugName === item1.PreviousPageURL
  //     );
  //     return roomDetailPage;
  // })
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(()=>{

    if ((templateData?.BEWidgetFlags?.MBS ?? "") === "Y") {
      let swifttag = document.createElement('script')
      swifttag.src = 'https://www.swiftbook.io/plugin/js/booking-service.min.js';
      swifttag.setAttribute('id', "propInfo");
      swifttag.setAttribute('propertyid', templateData.propertyDetails.staahPropertyIDEnc);
      swifttag.setAttribute('cal-rendererId', "quickbook-widget");
      swifttag.setAttribute('JDRN', "Y");
      swifttag.setAttribute('redirect', "off");
      if (navigator.userAgent !== "ReactSnap") {
        document.body.appendChild(swifttag);
      }
    }


  }, [])


  return (
    <div>
      <MetaTags
        metaTitle={data.metaTitle || ""}
        metaDesc={data.metaDesc || ""}
        metaKeywords={data.metaKeywords || ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      {data.menuSlugName !== "/" &&
        (data.headerImage ? (
          <HeaderImage bgImage={data.headerImage}></HeaderImage>
        ) : data.sliderImages && data.sliderImages.sliderImage ? (
          <HeaderImage>
            <ParallaxWrapper
              className="parallax"
              bgImage={data.sliderImages.sliderImage[0]}
            />
            <div className="headerContentWrapper">
              <div className="headerContent">
                <h1>{data.menuLinkName}</h1>
              </div>
            </div>
          </HeaderImage>
        ) : (
          <NoHeaderImage bgColor="#fff" />
        ))}
      {data.menuSlugName === "/" ? (
        <>
          {data.sliderImages && (
            <>
              <HomePageSlider11
                sliderImages={data.sliderImages}
                propertyLogo={templateData.propertyDetails.propertyLogo}
              />
            </>
          )}
          <div id="quickbook-widget"></div>
          <HomePageComponent templateData={templateData} />
        </>
      ) : data.menuSlugName === "/about-us/" ? (
        <CommonComponent id="about-us" bgColor="#fff" textColor="#000">
          <h2 class="left">About Us</h2>
          <div
            className="roomTypeContent  noLeftMargin"
            dangerouslySetInnerHTML={{
              __html: decodeContent(
                templateData.propertyDetails.propertyDescription
              ),
            }}
          ></div>
        </CommonComponent>
      ) : data.pageId === "3" ? (
        <CommonComponent bgColor="#fff" textColor="#000">
          <h2 class="left">Property Facilities</h2>

          <ul className="facilities">
            {facilities.map((item, index) => (
              <li>
                <FontAwesomeIcon icon={faChevronRight} />
                {item}
              </li>
            ))}
          </ul>
        </CommonComponent>
      ) : data.pageId === "2" ? (
        <AccommodationComponent templateData={templateData} />
      ) : data.pageId === "4" ? (
        <GalleryComponent propertyImage={propertyImage} roomTypes={roomTypes} />
      ) : data.pageId === "5" ? (
        <ContactUsComponent templateData={templateData} />
      ) : data.pageId === "6" && templateData.reviewMinderWidget === "1" ? (
        <CommonComponent bgColor="#fff" textColor="#000">
          {!data.headerImage && <h2 class="left">Reviews</h2>}
          <ReviewsComponent templateData={templateData} />
        </CommonComponent>
      ) : data.MenuLinkAdditonalPageID > 0 ? (
        <CommonComponent bgColor="#fff" textColor="#000">
          {templateData.multiPagesMain.mainPage.map((item, index) => {
            return (
              data.MenuLinkAdditonalPageID === item.websitepageid && (
                <>
                  <Row>
                    <Col
                      xs={12}
                      md={item.websitepageImages.mPImages.length > 0 ? 6 : 12}
                    >
                      {item.websitepageImages.mPImages.length === 0 && (
                        <h2 class="left">{item.websitetitle} </h2>
                      )}
                      <div
                        className="roomTypeContent marginTop"
                        dangerouslySetInnerHTML={{
                          __html: decodeContent(item.websitepagecontent),
                        }}
                      ></div>
                    </Col>
                    {item.websitepageImages && (
                      <Col xs={12} md={6} style={{marginTop:'40px'}}>
                        {typeof item.websitepageImages.mPImages === "string"
                          ? item.websitepageImages.mPImages && (
                            <SliderWrapper>
                            <Slider {...settings}>
                              {item.websitepageImages.mPImages.map(
                                (item, index) => (
                                  <SlideImage key={index}>
                                    <img src={item} alt="" />
                                  </SlideImage>
                                )
                              )}
                            </Slider>
                          </SliderWrapper>
                            )
                          : item.websitepageImages.mPImages.length > 0 && (
                            <SliderWrapper>
                            <Slider {...settings}>
                              {item.websitepageImages.mPImages.map(
                                (item, index) => (
                                  <SlideImage key={index}>
                                    <img src={item} alt="" />
                                  </SlideImage>
                                )
                              )}
                            </Slider>
                          </SliderWrapper>
                            )}
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {item.multiPagesSub &&
                      item.multiPagesSub.subPage &&
                      item.multiPagesSub.subPage.map((item1, index) => {
                        return item.externalPages === "0" ? (
                          <Col xs={12} sm={4}>
                            <CardWrapper>
                              <SubPageCardComponent
                                pageId={item1.websitepageid}
                                menuSlugName={item1.websiteSlugName}
                                headerImage={`${
                                  typeof item1.websitepageImagesSub
                                    .MPImagesSub === "string"
                                    ? item1.websitepageImagesSub.MPImagesSub
                                      ? item1.websitepageImagesSub.MPImagesSub
                                      : noImageUrl
                                    : item1.websitepageImagesSub.MPImagesSub[0]
                                }`}
                                MenuLinkpageName={item1.websitetitle}
                              />
                            </CardWrapper>
                          </Col>
                        ) : (
                          <Col xs={12} md={4}>
                            <CardWrapper>
                              <SubPageCardComponent
                                pageId={item1.websitepageid}
                                menuSlugName={
                                  data.menuSlugName + item1.websiteSlugName
                                }
                                headerImage={`${
                                  typeof item1.websitepageImagesSub
                                    .MPImagesSub === "string"
                                    ? item1.websitepageImagesSub.MPImagesSub
                                      ? item1.websitepageImagesSub.MPImagesSub
                                      : noImageUrl
                                    : item1.websitepageImagesSub.MPImagesSub[0]
                                }`}
                                MenuLinkpageName={item1.websitetitle}
                              />
                            </CardWrapper>
                          </Col>
                        );
                      })}
                  </Row>
                </>
              )
            );
          })}
        </CommonComponent>
      ) : (
        <CommonComponent>
          <h2>{data.MenuLinkpageName}</h2>
        </CommonComponent>
      )}
    </div>
  );
};

export default PagesComponent;
