import React,{useState} from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { SectionWrapper } from "../Template9Pages/templateConstants";
import { propertyImageURL, sliderImageURL, noImageUrl } from "../../global/constants";
import MetaTags from "../../global/MetaTags";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import constant from "../Template9Pages/constant";
import { decodeContent } from "../../global/constants";
import "slick-carousel/slick/slick-theme.css";
import media from "../../global/media";
import Lightbox from "react-image-lightbox";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent2";

const SubItemPagesComponentWrapper = styled.div`
  padding: 3em 0;
background: ${(prop) => prop.bgColor || "#191919"};
.breadCrumb{
  margin-top: 50px;
}
  h2 {
    line-height: 1.25;
    font-size: 2.25rem;
    font-weight: 500;
    color: #000;

  }
  
  img{
      padding-bottom:10px;
      width:90%;
      display:flex;
      justify-content:center;

    ${media.sm}{
      width:100%;
      
    }
  }
`;

const SliderWrapper = styled.div`
  .slick-arrow {
    position: absolute;
    padding: 26px;
    margin: 0;
    background-color: #0a0a0a;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-family: inherit;
    font-size: 0;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    z-index: 5;

    ${media.sm} {
      display: inline-block !important;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    background-image: url("https://mollerrothe.com/wp-content/themes/mollerrothe-theme/assets/img/arrow-left.svg");
    background-repeat: no-repeat;
    background-position: center;
    left: 20px;
    &:before {
      content: "";
    }
  }
  .slick-next {
    background-image: url("https://mollerrothe.com/wp-content/themes/mollerrothe-theme/assets/img/arrow-right.svg");
    background-repeat: no-repeat;
    background-position: center;
    right: 20px;
    &:before {
      content: "";
    }
  }
`;

const SlideImage = styled.div`
  img {
    width: 100%;
  }
`;

const HeaderImage = styled.div`
  height: 200px;
  background-size: cover;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
`;

const NoHeaderImage = styled.div`
background: ${(prop) => prop.bgColor || "#191919"};
  height: 100px;
  /* ${media.md}{
  height: 100px;
  } */
`;

const SubItemPagesComponent = ({ data, templateData,menuFound}) => {
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const openLightbox = (type, key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  const images=data.websitepageImagesSub.MPImagesSub;
  
  const lightBoxImages = [];
  images.map((value, index) =>
  lightBoxImages.push({
      image: propertyImageURL + value,
      type: "property",
    })
  );

  return (
    <SubItemPagesComponentWrapper>
      <MetaTags
        metaTitle={data.metaTitle ? data.metaTitle : data.websitetitle}
        metaDesc={data.metaDesc ? data.metaDesc : ""}
        metaKeywords={data.metaKeywords ? data.metaKeywords : ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      {data.headerImage 
        ? 
          <HeaderImage bgImage={data.headerImage} bgColor="#191919"/> 
        : 
          <NoHeaderImage />
      }
      <Grid>
        <div className="breadCrumb">
          <BreadCrumbComponent
            currentUrl = {data.websitetitle}
            previousUrl = {menuFound.menuSlugName}
            previousName = {menuFound.menuLinkName}
            bgColor="transparent"
          />
        </div>
          
      </Grid>
      <SectionWrapper className="margin" bgColor="#191919">
        <Grid>
          {images.length > 0 ?  
          <Row>
            <Col xs={12} md={6}>
            <h2>{data.websitetitle}</h2>
            <p className="facilities"
              dangerouslySetInnerHTML={{
                __html: decodeContent(
                  data.websitepagecontent
                ),
              }}
            ></p>
          </Col>
          <Col xs={12} sm={6}>
          { 
            <SliderWrapper>
              <Slider {...settings}>
                  {images.map(
                    (item, index) => (
                      <SlideImage
                          key={index}
                      >
                          <img
                              src={sliderImageURL +item}
                              alt=""
                          />
                      </SlideImage>
                    )
                  )}
                </Slider>
            </SliderWrapper>
          }
          </Col>
          </Row>
          :
          <Row>
            <Col xs={12} md={12}>
              <h2>{data.websitetitle}</h2>
              <p className="facilities"
                dangerouslySetInnerHTML={{
                  __html: decodeContent(
                    data.websitepagecontent
                  ),
                }}
              ></p>
            </Col>
          </Row>
          }
        </Grid>

        {open && (
        <Lightbox
          mainSrc={lightBoxImages[photoIndex].image}
          nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
          prevSrc={lightBoxImages[(photoIndex + lightBoxImages.length - 1) % lightBoxImages.length]}
          onCloseRequest={() => setOpen(false)}
          imageCaption={lightBoxImages[photoIndex].imgLabel}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + lightBoxImages.length - 1) % lightBoxImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lightBoxImages.length)
          }
        />
      )}
      </SectionWrapper>
    </SubItemPagesComponentWrapper>
  );
};

export default SubItemPagesComponent;
