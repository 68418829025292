import React, { useEffect,useState } from "react";
import styled from "styled-components";
import MetaTags from "../../global/MetaTags";
import media from "../../global/media";
import { Row, Col } from "react-flexbox-grid";
import { sliderImageURL, decodeContent } from "../../global/constants";
import constant from "./constant";
import HomePageSlider8 from "../../HomePageSlider/HomePageSlider8";
import HomePageComponent from "../Template8Pages/HomePageComponent";
import CommonComponent from "../Template8Pages/CommonComponent";
import AccommodationComponent from "../Template8Pages/AccommodationComponent";
import GalleryComponent from "../Template8Pages/GalleryComponent";
import ContactUsComponent from "../Template8Pages/ContactUsComponent";
import ReviewsComponent from "../Template8Pages/ReviewsComponent";
import TemplateCardComponent from "./TemplateCardComponent";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeaderImage = styled.div`
  height: 80vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  .headerContentWrapper {
    margin-bottom: 10px;
    margin-top: 100px;
    z-index: 2;
  }
  .headerContent {
    width: 100%;
    height: 100%;
    h1 {
      text-align: center;
      white-space: pre-wrap;
      font-size: 50px;
      font-weight: 500;
      margin-bottom: 30px;
      letter-spacing: 0.1em;
    }
    h3 {
      white-space: pre-wrap;
      font-size: 35px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 50px;
    }
  }
`;
const ParallaxWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* transform: translateZ(0px);
  transform-style: preserve-3d; */
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
  background-size: cover;
  overflow-y: hidden;
  /* background-attachment:fixed; */
`;

const NoHeaderImage = styled.div`
  height: 100px;
  ${media.sm} {
    height: 150px;
  }
`;
const SubPagesBlock = styled.div`
  a {
    color: ${constant.secondaryColor};
  }
  img {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    margin-top: 20px;

    ${media.md} {
      margin-top: 0;
    }
  }
  margin-top: 45px;

  margin-bottom: 20px;
  position: relative;
  /* background-image: url("${(prop) => prop.bgImage}");
  background-size: contain;
  background-repeat: no-repeat; */
  width: 100%;

  .content-desc {
    transition: visibility 0.3s linear, opacity 0.3s linear;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
  }
  .content-title {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }

  &:hover {
    .content-desc {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Caption = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  width: 100%;
  text-align: center;
  transform: translateY(15%);
  color: #fff;
  font-size: 1.4em;
  padding: 20px 50px;
  display: none;
  ${media.sm} {
    display: block;
  }
  a {
    text-align: center;
    white-space: pre-wrap;
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 0.05em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    font-weight: normal;
    color: #eee;
    margin: 5px 15px;
    /* border-right:3px solid #eee; */
    /* padding-right:10px; */
    position: relative;
    &:after {
      position: absolute;
      background: #eee;
      top: 0;
      right: -14px;
      content: "";
      width: 2px;
      height: 100%;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:list-style {
      border-right: none;
    }
    &:hover {
      opacity: 0.5;
    }
  }
`;

const CardWrapper = styled.div`
  margin-top: 60px;
`;

const MultiPageWrapper = styled.div`
  margin-top: 15px;
  padding: 10px 30px;
`;

const SubPagesBlockLink = styled.a`
  height: 400px;
  margin: 50px 0px;
  display: block;
  position: relative;
  background-image: url("${(prop) => prop.bgImage}");

  width: 100%;

  .content-title {
    position: absolute;
    color: #000;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }
`;

const SliderWrapper = styled.div`
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58, 49, 56, 0.8);
    &:before {
      font-size: 30px;
    }
    &:hover {
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    left: 20px;
    top: 40%;
  }
  .slick-next {
    right: 20px;
    top: 40%;
  }
`;

const SlideImage = styled.div`
  height: 300px;
  img {
    width: 100%;
  }
`;


const PagesComponent = ({ data, templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const facilities = templateData.propertyDetails.facilities.facility;
  const propertyImage = templateData.propertyImages;
  const noImageUrl = "/images/multipages/noimage.png";
  const MenuTypeDetail = templateData.menuLinks.menuLink;
  const MultiPageDetail = templateData.multiPagesMain.mainPage;
  const cardPropertyImages = templateData.propertyImages.image;
  const [bookingEngine] = useState(templateData.bookingEngine);

  let multiPage = 1;

  // useEffect(() => {

  //     window.addEventListener('scroll',()=>{
  //     var value= window.scrollY;
  //     document.querySelector('.parallax').style.top= value * 0.3 + "px";
  //   })
  //   return () => {
  //     cleanup
  //   }
  // }, [input])
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(()=>{

    if ((templateData?.BEWidgetFlags?.MBS ?? "") === "Y") {
      let swifttag = document.createElement('script')
      swifttag.src = 'https://www.swiftbook.io/plugin/js/booking-service.min.js';
      swifttag.setAttribute('id', "propInfo");
      swifttag.setAttribute('propertyid', templateData.propertyDetails.staahPropertyIDEnc);
      swifttag.setAttribute('cal-rendererId', "quickbook-widget");
      swifttag.setAttribute('JDRN', "Y");
      swifttag.setAttribute('redirect', "off");
      if (navigator.userAgent !== "ReactSnap") {
        document.body.appendChild(swifttag);
      }
    }


  }, [])


  return (
    <div>
      <MetaTags
        metaTitle={data.metaTitle || ""}
        metaDesc={data.metaDesc || ""}
        metaKeywords={data.metaKeywords || ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      {data.menuSlugName !== "/" &&
        (data.headerImage ? (
          <HeaderImage>
            <ParallaxWrapper className="parallax" bgImage={data.headerImage} />
            <div className="headerContentWrapper">
              <div className="headerContent">
                <h1>{templateData.propertyDetails.propertyName}</h1>
                <h3>{data.menuLinkName}</h3>
              </div>
            </div>
          </HeaderImage>
        ) : data.sliderImages && data.sliderImages.sliderImage ? (
          <HeaderImage>
            <ParallaxWrapper
              className="parallax"
              bgImage={data.sliderImages.sliderImage[0]}
            />
            <div className="headerContentWrapper">
              <div className="headerContent">
                <h1>{templateData.propertyDetails.propertyName}</h1>
                <h3>{data.menuLinkName}</h3>
              </div>
            </div>
          </HeaderImage>
        ) : (
          <NoHeaderImage />
        ))}

      {data.menuSlugName === "/" ? (
        <>
          {data.sliderImages && (
            <>
              <HomePageSlider8 sliderImages={data.sliderImages} />

              <Caption>
                {MenuTypeDetail.map(
                  (item, index) =>
                    item.pageId === "2" && (
                      <a href={`${process.env.PUBLIC_URL}` + item.menuSlugName}>{item.menuLinkName}</a>
                    )
                )}
                {MenuTypeDetail.map(
                  (item, index) =>
                    item.pageId >= 3 &&
                    item.pageId <= 5 && (
                      <a href={`${process.env.PUBLIC_URL}` + item.menuSlugName}>{item.menuLinkName}</a>
                    )
                )}
              </Caption>
            </>
          )}
          <div id="quickbook-widget"></div>
          <HomePageComponent templateData={templateData} />
        </>
      ) : data.menuSlugName === "/about-us/" ? (
        <>
          <BreadCrumbComponent
            currentUrl={data.menuLinkName}
          />
          <CommonComponent id="about-us">

            <h2 class="left">About us</h2>
            <div
              className="roomTypeContent"
              dangerouslySetInnerHTML={{
                __html: decodeContent(
                  templateData.propertyDetails.propertyDescription
                ),
              }}
            ></div>
          </CommonComponent>
        </>
      ) : data.pageId === "3" ? (
        <>
          <BreadCrumbComponent
            currentUrl={"Facilities"}
          />
          <CommonComponent>

            <h2 class="left">Property Facilities</h2>
            <ul className="facilities">
              {facilities.map((item, index) => (
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  {item}
                </li>
              ))}
            </ul>
          </CommonComponent>
        </>
      ) : data.pageId === "2" ? (
        <AccommodationComponent templateData={templateData} />
      ) : data.pageId === "4" ? (
        <GalleryComponent propertyImage={propertyImage} roomTypes={roomTypes} />
      ) : data.pageId === "5" ? (
        <ContactUsComponent templateData={templateData} />
      ) : data.pageId === "6" && templateData.reviewMinderWidget === "1" ? (
        <ReviewsComponent templateData={templateData} />
      ) : data.MenuLinkAdditonalPageID > 0 ? (
        <>

          {templateData.multiPagesMain.mainPage.map((item, index) => {

            return (
              data.MenuLinkAdditonalPageID === item.websitepageid && (
                <>
                  <BreadCrumbComponent
                    currentUrl={item.websitepagename}
                  />
                  <CommonComponent>
                    {/* { console.log(item.websitepageid),
                console.log(data.MenuLinkAdditonalPageID),
                console.log(data.menuSlugName),
                console.log("heelloo")
               } */}

                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        lg={item.websitepageImages.mPImages.length > 0 ? 6 : 12}
                      >
                        <h2 class="left">{item.websitetitle}</h2>
                        <div
                          className="roomTypeContent"
                          dangerouslySetInnerHTML={{
                            __html: decodeContent(item.websitepagecontent),
                          }}
                        ></div>
                      </Col>
                      {item.websitepageImages && (
                        <Col xs={12} md={12} lg={6} style={{marginTop:'100px'}}>
                          {typeof item.websitepageImages.mPImages === "string"
                            ? item.websitepageImages.mPImages && (
                              <SliderWrapper>
                                    <Slider {...settings}>
                                      {item.websitepageImages.mPImages.map(
                                        (item, index) => (
                                          <SlideImage key={index}>
                                            <img src={item} alt="" />
                                          </SlideImage>
                                        )
                                      )}
                                    </Slider>
                                  </SliderWrapper>
                            )
                            : item.websitepageImages.mPImages.length > 0 && (
                              <SliderWrapper>
                                    <Slider {...settings}>
                                      {item.websitepageImages.mPImages.map(
                                        (item, index) => (
                                          <SlideImage key={index}>
                                            <img src={item} alt="" />
                                          </SlideImage>
                                        )
                                      )}
                                    </Slider>
                                  </SliderWrapper>
                            )}
                        </Col>
                      )}
                    </Row>

                    <Row>
                      {item.multiPagesSub &&
                        item.multiPagesSub.subPage &&
                        item.multiPagesSub.subPage.map((item1, index) => {

                          return item.externalPages === "0" ? (
                            <Col xs={12} md={6}>
                              <CardWrapper>
                                <TemplateCardComponent
                                  pageId={item1.websitepageid}
                                  menuSlugName={item1.websiteSlugName}
                                  headerImage={`${typeof item1.websitepageImagesSub
                                      .MPImagesSub === "string"
                                      ? item1.websitepageImagesSub.MPImagesSub
                                        ? item1.websitepageImagesSub.MPImagesSub
                                        : noImageUrl
                                      : item1.websitepageImagesSub.MPImagesSub[0]
                                    }`}
                                  MenuLinkpageName={item1.websitetitle}
                                  propertyImages={cardPropertyImages}
                                  metaDesc={decodeContent(
                                    bookingEngine,
                                    item1.websitepagecontent
                                  )}
                                />
                              </CardWrapper>
                            </Col>
                          ) : (
                            <Col xs={12} md={6}>
                              <CardWrapper>
                                <TemplateCardComponent
                                  pageId={item1.websitepageid}

                                  menuSlugName={data.menuSlugName + item1.websiteSlugName}
                                  headerImage={`${typeof item1.websitepageImagesSub
                                      .MPImagesSub === "string"
                                      ? item1.websitepageImagesSub.MPImagesSub
                                        ? item1.websitepageImagesSub.MPImagesSub
                                        : noImageUrl
                                      : item1.websitepageImagesSub.MPImagesSub[0]
                                    }`}
                                  MenuLinkpageName={item1.websitetitle}
                                  metaDesc={item1.websitepagecontent}
                                  bookingEngine={String(bookingEngine)}
                                  multiPage={String(multiPage)}
                                />
                              </CardWrapper>
                            </Col>
                          );
                        })}
                    </Row>
                  </CommonComponent>
                </>
              )
            );

          })}
        </>
      ) : (
        <CommonComponent>
          <h2>{data.MenuLinkpageName}</h2>
        </CommonComponent>
      )}
    </div>
  );
};

export default PagesComponent;
