import React, { useState } from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfoWindowWrapper = styled.div`
  position: absolute;
  top: 2%;
  left: 30%;
  background: #000;
  padding: 20px;
  border-radius: 10px;
  width: 40%;
  color: #fff;
  font-size: 12px;
  p {
    color: #fff !important;
  }
  .fa-i {
    position: absolute;
    top: -10px;
    right: -10px;
    color: #000;
  }
  .propertyName {
    font-size: 14px;
  }
`;

const MapComponent = ({
  latitude,
  longitude,
  googleMap,
  address,
  iconImage,
  mapHeight,
  propertyDetails,
}) => {

  googleMap ='AIzaSyAsdMnINpXGDnszeI5ORuxJbjbOQlY0vBg';
  //console.log('latitude-------->',latitude);
  //console.log('longitude-------->',longitude);
  //console.log('googleMap-------->',googleMap);
  //console.log('address-------->',address);
  //console.log('iconImage-------->',iconImage);
  //console.log('mapHeight-------->',mapHeight);
  //console.log('propertyDetails-------->',propertyDetails);

  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };
  const zoom = 15;
  const [openWindow, setOpenWindow] = useState(false);
  const InfoWindowOnClick = () => {
    setOpenWindow(!openWindow);
  };

  return (
    <div
      style={{
        height: mapHeight,
        width: "100%",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMap }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={{ fullscreenControl: false }}
      />
      {openWindow && (
        <InfoWindowWrapper className="infoWindowWrapper">
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={InfoWindowOnClick}
            className="fa-i"
          />
          <p className="propertyName">{propertyDetails.propertyName}</p>
          <p>
            {decodeURIComponent(address.location)} <br />
            {address.city} <br />
            {address.address1} <br />
            {address.country}
          </p>
          <br />
        </InfoWindowWrapper>
      )}
      <Marker
        onClick={InfoWindowOnClick}
        iconImage={iconImage}
        text="Here"
        lat={center.lat}
        lng={center.lng}
        title="Hello World!"
      />
    </div>
  );
};

export default MapComponent;
