import React from "react";
import styled from "styled-components";
// import { Grid, Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import media from "../../global/media";
import { propertyImageURL, WidgetOpen } from "../../global/constants";
import constant from "../../Template/Template11Pages/constant";
import LanguageTranslatComponent from "../../global/LanguageTranslatComponent";

const HeaderLayout10Wrapper = styled.div`
  width: 100%;
  color: #eee;
  display: flex;
  justify-content: space-between;

  background: transparent;
  padding: 8px 20px;
  position: fixed;
  align-items: center;
  transition: 0.5s all ease-in-out;
  img {
    max-width: 200px;
    height: auto;
    opacity: 0;
  }
  .logo {
    width: ${(prop) => (prop.width ? prop.width / 2 + "px" : "auto")};
    height: ${(prop) => (prop.height ? prop.height / 2 + "px" : "auto")};
    ${media.sm} {
      width: ${(prop) => (prop.width ? prop.width + "px" : "auto")};
      height: ${(prop) => (prop.height ? prop.height + "px" : "auto")};
    }
  }
  &.sticky {
    background: rgba(64, 89, 0, 0.5);
    /* .faBars {
      background: #000;
      &:before,
      &:after {
        background: #000;
      }
    }
    */
    .bookNowButton {
      color: ${constant.primaryColor};
      background: #fff !important;
    }
  }
  &.shiftHeader {
    /* width: calc(100% - 250px); */
    display: none;
  }
  .header-links {
    animation: bookNowShow 0.3s ease-in;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    @keyframes bookNowShow {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  /* ${media.lg}{
    padding: 40px;
  } */
`;

const NavbarLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70%;
  align-items: center;
  opacity: 0;
`;

const NavBarLinkBook = styled.button`
  background: ${(prop) =>
    prop.bgColor
      ? prop.bgColor
      : constant.primaryColor
      ? constant.primaryColor
      : "#fff"};
  outline: 0;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  margin: 1rem 0 1rem 0;
  padding: 1em 2em;
  border: 1px solid transparent;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  :hover {
    background: #000;
    color: #fff;
  }
`;
const NavBarWrapper = styled.div`
  font-size: 28px;
  padding: 22px 10px;
  margin-left: 20px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  transition: all 0.5ms ease-in;
  color: #000;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  background: ${constant.primaryColor};

  .icon-bar {
    position: relative;
    width: 24px;
    height: 3px;

    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    pointer-events: none;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      top: -8px;
      border-radius: 5px;

      background-color: #fff;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      top: 8px;
      border-radius: 5px;
      background-color: #fff;
    }
    &.faBars {
      display: ${(prop) =>
        prop.navigationToggle === "none" ? "block" : "none"};
    }
  }
`;

const HeaderLayout10 = ({
  propertyDetails,
  colorConfiguration,
  onNavClick,
  navigationToggle,
}) => (
  <HeaderLayout10Wrapper
    id="headerWrapper"
    headerColor={colorConfiguration.headercolor}
    width={propertyDetails.propertyLogoWidth || ""}
    height={propertyDetails.propertyLogoHeight || ""}
  >
     {propertyDetails?.propertyLogo ?(
    <Link to="/" onClick={() => window.scrollTo(0, 0)}>
      <img
        className="logo header-links"
        src={propertyImageURL + propertyDetails.propertyLogo}
        width={propertyDetails.propertyLogoWidth || "auto"}
        height={propertyDetails.propertyLogoHeight || "auto"}
        alt={propertyDetails.propertyName}
      />
    </Link>
    ):(
        <h3>{propertyDetails.propertyName}</h3>)}
    <NavbarLinks className="header-links" id="header-link">
      <NavBarLinkBook
        className="bookNowButton"
        id="active-link"
        onClick={() => WidgetOpen(true)}
        bgColor={constant.primaryColor}
      >
        Book Now
      </NavBarLinkBook>
      <div className="langDbBtn-11">
        <LanguageTranslatComponent></LanguageTranslatComponent>
      </div>
      
      <NavBarWrapper
        onClick={() => onNavClick(false)}
        navigationToggle={navigationToggle}
      >
        <div className="icon-bar faBars"></div>
        {/* <FontAwesomeIcon icon={faTimes} className="icon faTimes" /> */}
      </NavBarWrapper>
    </NavbarLinks>
  </HeaderLayout10Wrapper>
);

export default HeaderLayout10;
