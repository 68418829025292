import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MetaTags from "../../global/MetaTags";
import media from "../../global/media";
import { Row, Col } from "react-flexbox-grid";
import { sliderImageURL, decodeContent } from "../../global/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import constant from "./constant";
import HomePageSlider7 from "../../HomePageSlider/HomePageSlider7";
import HomePageComponent from "../Template7Pages/HomePageComponent";
import CommonComponent from "../Template7Pages/CommonComponent";
import AccommodationComponent from "../Template7Pages/AccommodationComponent";
import GalleryComponent from "../Template7Pages/GalleryComponent";
import ContactUsComponent from "../Template7Pages/ContactUsComponent";
import ReviewsComponent from "../Template7Pages/ReviewsComponent";
import { SectionWrapper } from "./templateConstants";
import BreadCrumbComponent from "../../global/BreadCrumbComponent/BreadCrumbComponent";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const HeaderImage = styled.div`
  height: 300px;
  padding-top: 30%;
  background-size: cover;
  background-image: url(${(prop) => prop.bgImage});
  background-position: center center;
  animation-delay: 1s;
  animation: bounceBgImage 10s linear infinite;

  @keyframes bounceBgImage {
    0%,
    100% {
      background-position: 50% 100%;
    }
    50% {
      background-position: 50% 0;
    }
  }
`;
const NoHeaderImage = styled.div`
  height: 100px;
`;
const SubPagesBlock = styled.div`
  a {
    color: ${constant.secondaryColor};
  }
  img {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    margin-top: 20px;
    ${media.md} {
      margin-top: 0;
    }
  }
  min-height: 400px;
  margin-bottom: 20px;
  position: relative;
  /* background-image: url("${(prop) => prop.bgImage}");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; */
  width: 100%;

  .content-desc {
    transition: visibility 0.3s linear, opacity 0.3s linear;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
  }
  .content-title {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }

  &:hover {
    .content-desc {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const SubPagesBlockLink = styled.a`
  min-height: 400px;
  margin: 50px 0px;
  display: block;
  position: relative;
  background-image: url("${(prop) => prop.bgImage}");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  .content-title {
    position: absolute;
    color: #000;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }
`;
const SliderWrapper = styled.div`
  .slick-arrow {
    z-index: 10;
    font-size: 2em;
    width: 50px;
    height: 50px;
    background: rgb(58, 49, 56, 0.8);
    &:before {
      font-size: 30px;
    }
    &:hover {
      background: ${constant.primaryColor};
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: none;
    color: #fff;
  }

  .slick-prev {
    left: 20px;
    top: 40%;
  }
  .slick-next {
    right: 20px;
    top: 40%;
  }


`;



const SlideImage = styled.div`
  height: 300px;
  img {
    width: 100%;
  }
`;

const PagesComponent = ({ data, templateData }) => {
  const roomTypes = templateData.roomTypes.roomType;
  const facilities = templateData.propertyDetails.facilities.facility;
  const propertyImage = templateData.propertyImages;
  const noImageUrl = "/images/multipages/noimage.png";
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(()=>{

    if ((templateData?.BEWidgetFlags?.MBS ?? "") === "Y") {
      let swifttag = document.createElement('script')
      swifttag.src = 'https://www.swiftbook.io/plugin/js/booking-service.min.js';
      swifttag.setAttribute('id', "propInfo");
      swifttag.setAttribute('propertyid', templateData.propertyDetails.staahPropertyIDEnc);
      swifttag.setAttribute('cal-rendererId', "quickbook-widget");
      swifttag.setAttribute('JDRN', "Y");
      swifttag.setAttribute('redirect', "off");
      if (navigator.userAgent !== "ReactSnap") {
        document.body.appendChild(swifttag);
      }
    }


  }, [])


  return (
    <div>
      <MetaTags
        metaTitle={data.metaTitle || ""}
        metaDesc={data.metaDesc || ""}
        metaKeywords={data.metaKeywords || ""}
        propertyFavicon={templateData.propertyDetails.propertyFavicon}
      />
      {data.menuSlugName !== "/" &&
        (data.headerImage ? (
          <HeaderImage bgImage={data.headerImage} />
        ) : data.sliderImages && data.sliderImages.sliderImage ? (
          <HeaderImage bgImage={data.sliderImages.sliderImage[0]} />
        ) : (
          <NoHeaderImage />
        ))}


      {data.menuSlugName === "/" ? (
        <>
          {data.sliderImages && (
            <HomePageSlider7 sliderImages={data.sliderImages} />
            

          )}
          
          <div id="quickbook-widget"></div>
          <HomePageComponent templateData={templateData} />
        </>
      ) : data.menuSlugName === "/about-us/" ? (
        <>
          <BreadCrumbComponent currentUrl={"About us"} />
          <CommonComponent>
            <div className="minheight">
              <h2>
                About us<span></span>
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeContent(
                    templateData.propertyDetails.propertyDescription
                  ),
                }}
              ></div>
            </div>
          </CommonComponent>
        </>
      ) : data.pageId === "3" ? (
        <>
          <BreadCrumbComponent currentUrl={"Facilities"} />
          <CommonComponent>
            <div className="minheight">
              <h2>
                Property Facilities<span></span>
              </h2>
              <ul className="facilities">
                {facilities.map((item, index) => (
                  <li>
                    <FontAwesomeIcon icon={faChevronRight} />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </CommonComponent>
        </>
      ) : data.pageId === "2" ? (
        <AccommodationComponent templateData={templateData} />
      ) : data.pageId === "4" ? (
        <GalleryComponent propertyImage={propertyImage} roomTypes={roomTypes} />
      ) : data.pageId === "5" ? (
        <ContactUsComponent templateData={templateData} />
      ) : data.pageId === "6" && templateData.reviewMinderWidget === "1" ? (
        <ReviewsComponent templateData={templateData} />
      ) : data.MenuLinkAdditonalPageID > 0 ? (
        <>
          {templateData.multiPagesMain.mainPage.map((item, index) => {
            return (
              data.MenuLinkAdditonalPageID === item.websitepageid && (
                <>
                  <BreadCrumbComponent currentUrl={item.websitepagename} />
                  <CommonComponent>
                    <div className="minheight">
                      <SectionWrapper>
                        <Row>
                          <Col
                            xs={12}
                            md={12}
                            lg={
                              item.websitepageImages.mPImages.length > 0
                                ? 6
                                : 12
                            }
                          >
                            <h2>
                              {item.websitetitle}
                              <span></span>
                            </h2>
                            <div
                              className="content-desc"
                              dangerouslySetInnerHTML={{
                                __html: decodeContent(item.websitepagecontent),
                              }}
                            ></div>
                          </Col>
                          {item.websitepageImages && (
                            <Col xs={12} md={12} lg={6} style={{marginTop:'60px'}}>
                              {typeof item.websitepageImages.mPImages ===
                                "string"
                                ? item.websitepageImages.mPImages && (
                                  <SliderWrapper>
                                    <Slider {...settings}>
                                      {item.websitepageImages.mPImages.map(
                                        (item, index) => (
                                          <SlideImage key={index}>
                                            <img src={item} alt="" />
                                          </SlideImage>
                                        )
                                      )}
                                    </Slider>
                                  </SliderWrapper>
                                )
                                : item.websitepageImages.mPImages.length >
                                0 && (
                                  <SliderWrapper>
                                  <Slider {...settings}>
                                    {item.websitepageImages.mPImages.map(
                                      (item, index) => (
                                        <SlideImage key={index}>
                                          <img src={item} alt="" />
                                        </SlideImage>
                                      )
                                    )}
                                  </Slider>
                                </SliderWrapper>
                                )}
                            </Col>
                          )}
                        </Row>
                        <Row>
                          {item.multiPagesSub &&
                            item.multiPagesSub.subPage &&
                            item.multiPagesSub.subPage.map((item1, index) => {
                              return item.externalPages === "0" ? (
                                <Col xs={12} md={6}>
                                  <SubPagesBlock
                                    bgImage={`${typeof item1.websitepageImagesSub
                                        .MPImagesSub === "string"
                                        ? item1.websitepageImagesSub.MPImagesSub
                                          ? item1.websitepageImagesSub
                                            .MPImagesSub
                                          : noImageUrl
                                        : item1.websitepageImagesSub
                                          .MPImagesSub[0]
                                      }`}
                                  >
                                    <div className="content-title">
                                      {item1.websitetitle}
                                    </div>
                                    <div
                                      className="content-desc"
                                      dangerouslySetInnerHTML={{
                                        __html: decodeContent(
                                          item1.websitepagecontent
                                        ),
                                      }}
                                    ></div>
                                  </SubPagesBlock>
                                </Col>
                              ) : (
                                <Col xs={12} md={6}>
                                  <SubPagesBlockLink
                                    href={
                                      `${process.env.PUBLIC_URL}` +
                                      data.menuSlugName + item1.websiteSlugName
                                    }
                                    bgImage={`${typeof item1.websitepageImagesSub
                                        .MPImagesSub === "string"
                                        ? item1.websitepageImagesSub.MPImagesSub
                                          ? item1.websitepageImagesSub
                                            .MPImagesSub
                                          : noImageUrl
                                        : item1.websitepageImagesSub
                                          .MPImagesSub[0]
                                      }`}
                                  >
                                    <div className="content-title">
                                      {item1.websitetitle}
                                    </div>
                                  </SubPagesBlockLink>
                                </Col>
                              );
                            })}
                        </Row>
                      </SectionWrapper>
                    </div>
                  </CommonComponent>
                </>
              )
            );
          })}
        </>
      ) : (
        <CommonComponent>
          <h2>
            {data.MenuLinkpageName}
            <span></span>
          </h2>
        </CommonComponent>
      )}
    </div>
  );
};

export default PagesComponent;
