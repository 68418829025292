import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";
import Lightbox from "react-image-lightbox";
import CommonComponent from "./CommonComponent";
import { propertyImageURL, sliderImageURL } from "../../global/constants";
import "react-image-lightbox/style.css";
import constant from "./constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
const GalleryWrapper = styled.div`
  margin-top: 40px;
`;
const GalleryImageWrap = styled.div`
  height: 250px;
  margin-bottom: 20px;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:hover {
      padding: calc(2% - 3px);
    }
  }
`;

const ClickItemWrapper = styled.div`
  text-align: center;
  margin-top: 15px;
  span {
    padding-left: 5px;
  }
  .iconGallery {
    color: #fff;
  }
`;

const ClickItem = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
  font-size: 15px;
  color: ${constant.bgBlack};
  &:hover {
    color: ${constant.secondaryColor};
    opacity: 0.7;
  }
  &.active {
    color: ${constant.secondaryColor};
    /* border-bottom: 1px solid ${constant.secondaryColor}; */
    opacity: 0.7;
  }
`;
const GalleryComponent = ({ propertyImage, roomTypes }) => {
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [display, setDisplay] = useState(0);
  const [roomDisplay, setRoomDisplay] = useState(0);
  const [roomCatId, setRoomCatId] = useState("all");
  const images = [];
  const roomTypeNames = [];

  propertyImage.image.map((value, index) =>
    images.push({
      image: propertyImageURL + value,
      imgLabel: atob(propertyImage.imgLabel[index]),
      type: "property",
    })
  );
  if (roomTypes.length > 0) {
    roomTypes.map((item, index) => {
      item.RoomTypeImages &&
        item.RoomTypeImages.Images.map((item1, index) =>
          images.push({
            image: sliderImageURL + item1,
            imgLabel: atob(item.RoomTypeImages.imgLabel[index]),
            type: "roomtypes", //
            class: item.RoomcatgId,
          })
        );
      roomTypeNames.push({
        id: item.RoomcatgId,
        name: item.RoomTypeName.section,
      });
      return false;
    });
  }

  const setRoomPhotoDisplay = (display, index, catId) => {
    setRoomDisplay(index);
    setDisplay(display);
    setRoomCatId(catId);
  };
  const setPhotoDisplay = (index) => {
    setDisplay(index);
    if (index === 0) {
      setRoomDisplay(0);
      setRoomCatId("all");
    }
  };
  const openLightbox = (type, key) => {
    setPhotoIndex(key);
    setOpen(true);
  };

  return (
    <div>
      <CommonComponent bgColor="#191919">
        <h2>Gallery</h2>
        <ClickItemWrapper>
          <ClickItem
            className={display === 0 ? "active" : ""}
            onClick={() => setPhotoDisplay(0)}
          >
            All Photos
            <span>
              {display === 0 && (
                <FontAwesomeIcon icon={faCheckDouble} className="iconGallery" />
              )}
            </span>
          </ClickItem>
          <ClickItem
            className={display === 1 ? "active" : ""}
            onClick={() => setPhotoDisplay(1)}
          >
            Property Photos
            <span>
              {display === 1 && (
                <FontAwesomeIcon icon={faCheckDouble} className="iconGallery" />
              )}
            </span>
          </ClickItem>
          <ClickItem
            className={display === 2 ? "active" : ""}
            onClick={() => setPhotoDisplay(2)}
          >
            Room Photos
            <span>
              {display === 2 && (
                <FontAwesomeIcon icon={faCheckDouble} className="iconGallery" />
              )}
            </span>
          </ClickItem>
        </ClickItemWrapper>

        {/* to show room type names */}
        {display === 2 && (
          <ClickItemWrapper>
            <ClickItem
              className={roomDisplay === 0 ? "active" : ""}
              onClick={() => setRoomPhotoDisplay(2, 0, "all")}
            >
              All
              <span>
                {roomDisplay === 0 && (
                  <FontAwesomeIcon
                    icon={faCheckDouble}
                    className="iconGallery"
                  />
                )}
              </span>
            </ClickItem>
            {roomTypeNames.map((value, index) => {
              return (
                <ClickItem
                  className={roomDisplay === index + 1 ? "active" : ""}
                  onClick={() => setRoomPhotoDisplay(2, index + 1, value.id)}
                >
                  {value.name}
                  <span>
                    {roomDisplay === index + 1 && (
                      <FontAwesomeIcon
                        icon={faCheckDouble}
                        className="iconGallery"
                      />
                    )}
                  </span>
                </ClickItem>
              );
            })}
          </ClickItemWrapper>
        )}
        <GalleryWrapper>
          <Row>
            {(display === 0 || display === 1) &&
              images.map((value, index) => {
                return (
                  value.type === "property" && (
                    <Col xs={12} sm={6} md={4} lg={4} key={index}>
                      <GalleryImageWrap>
                        <img
                          src={value.image}
                          onClick={() => openLightbox("property", index)}
                          alt={value.imgLabel}
                        />
                      </GalleryImageWrap>
                    </Col>
                  )
                );
              })}
            {(display === 0 || display === 2) &&
              images.map((value, index) => {
                return (
                  value.type === "roomtypes" &&
                  (value.class === roomCatId || roomCatId === "all") && (
                    <Col xs={12} sm={6} md={4} lg={4} key={index}>
                      <GalleryImageWrap>
                        <img
                          src={value.image}
                          onClick={() => openLightbox("roomtypes", index)}
                          alt={value.imgLabel}
                        />
                      </GalleryImageWrap>
                    </Col>
                  )
                );
              })}
          </Row>
        </GalleryWrapper>
      </CommonComponent>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex].image}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          imageCaption={images[photoIndex].imgLabel}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default GalleryComponent;
/*

  {/* to show images based on roomType }
  {(display === 2) && 
    images.map((item,index)=>{
      return (item.type === roomTypeNames[roomDisplay] && (
        <Col xs={12} sm={6} md={4} lg={4} key={index}>
          <GalleryImageWrap>
            <img
              src={item.image}
              onClick={() => openLightbox(item.type, index)}
              alt={item.imgLabel}
            />
          </GalleryImageWrap>
        </Col>
      ));
    })   
  }
  */
